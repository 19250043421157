
import { Vue, Component, Prop } from 'vue-property-decorator'

import 'whatwg-fetch'

@Component({})
export default class LoadSecret extends Vue {
  private password: string = '';
  private needsPassword: boolean = false;
  private passwordValidationError: boolean = false;

  @Prop()
  private url: string|undefined;

  private async mounted () {
    const res = await fetch('/api/secret/' + this.url + '/info')

    const resData = await res.json()

    if (res.status === 404) {
      this.$router.push({ name: 'Error' })
    }

    this.needsPassword = resData.hasPassword
  }

  private async show () {
    if (this.needsPassword) {
      const res = await fetch('/api/secret/' + this.url + '?password=' + encodeURIComponent(this.password))

      if (res.status === 403) {
        this.showPasswordValidationError()
        return // wrong password
      }

      this.$store.dispatch('setSecret', (await res.json()))
    }
    this.$router.push({
      name: 'showSecret',
      params: { url: (this.url as string) }
    })
  }

  private showPasswordValidationError () {
    this.passwordValidationError = true
  }
}
